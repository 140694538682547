:root {
    --theme-font: Arial, Helvetica, sans-serif;

    --theme-text: #888b8e;
    --theme-contrast: #87212E;
    --theme-dark-text: black;

    --theme-tint: #f6f6f6;
    --theme-light-tint: #dee2e6;
    --theme-dark-tint: #cbcdd1;
    --theme-heading-tint: #f1f1f2;
    --theme-sidebar-tint: #f6f6f6;

    --theme-scored-tint: #feede4;
    --theme-progress-tint: #fff8ef;

    --theme-level-1: black;
    --theme-level-2: #66696d;
    --theme-level-3: #a8aaac;

    --theme-primary: #c23c3e;
    --theme-secondary: #002953;

    --theme-highlight: #4254D5;
    --theme-progress: #00abe2;
    --theme-danger: #d2232a;

    --theme-logo-url: url('/assets/moog-logo.svg');
    --theme-logo-width: 140px;
    --theme-logo-height: 35px;
    --theme-footer-logo-display: none;

    --theme-nav-image: none; /* url('assets/blue-hdr.png'); */
    --theme-nav-height: 75px;
    --theme-nav-bg: white; /* diageo: var(--sidebar) */

    --theme-headings-weight: normal;
    --theme-link-color: black;

    --theme-sidebar-height: min-content; /* diageo: 100% */

    --theme-checkmark-url: url('/assets/icons/tick-icon.svg');
    --theme-selected-question-border: 0px;
    --theme-unanswered-question-bg: #f6f6f6;
}
