body {
    font-family: var(--theme-font);
    color: var(--theme-text);
    font-size: 16px;
}

.waiting {
    cursor: wait;
}

h2 {
    font-size: 19px;
    font-weight: var(--theme-headings-weight); /* 600 */
    padding-bottom: 8px;
    border-bottom: 2px solid var(--theme-dark-tint);
    letter-spacing: 0.02em;
}

h3 {
    font-size: 18px;
    font-weight: var(--theme-headings-weight); /* 700 */
    padding-bottom: 10px;
}

h4 {
    font-size: 17px;
    font-weight: var(--theme-headings-weight); /* 600 */
    padding-bottom: 6px;
}

table {
    width: 100%;
}
tbody {
    color: var(--theme-dark-text);
}
th {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
}
th:not(:last-child) {
    padding-right: 5px;
}
td {
    vertical-align: top;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 5px;
}
td:not(:last-child) {
    padding-right: 5px;
}
tr:nth-child(even) {
    background: var(--theme-tint);
}
tr:not(:last-child) {
    border-bottom: 1px solid var(--theme-light-tint);
}

th {
    text-transform: uppercase;
    color: var(--theme-text);
    font-weight: var(--theme-headings-weight);
    letter-spacing: 1.5;
    font-size: 12px;
}
td {
    font-size: 14px;
}

.button,
button {
    background-color: var(--theme-contrast);
    color: white;
    font-size: 13px;
    padding: 10px;
    border: none;
    border-radius: 12px;
    /* text-transform: uppercase; */
    padding: 2px 20px;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
    min-width: 100px;
}
.action-button {
    color: white;
    background-color: var(--theme-highlight);
    padding: 8px;
    width: 100%;
    border-radius: 3px;
    font-size: 14px;
    text-transform: uppercase;
}
a {
    color: var(--theme-link-color);
    text-decoration: none;
}
button[disabled] {
    background-color: lightgray !important;
}
select {
    color: white;
    font-weight: bold;
    background-color: var(--theme-contrast);
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
}

.tiered-colors > select:nth-child(1 of select:not([hidden])) {
    background-color: var(--theme-level-1);
}
.tiered-colors > select:nth-child(2 of select:not([hidden])) {
    background-color: var(--theme-level-2);
}
.tiered-colors > select:nth-child(3 of select:not([hidden])) {
    background-color: var(--theme-level-3);
}

.accordion-header button {
    letter-spacing: initial;
}

.heading-icon {
    margin-left: 5px;
    filter: invert(52%) sepia(0%) saturate(4%) hue-rotate(267deg)
        brightness(97%) contrast(96%);
    max-width: 14px;
    vertical-align: top;
}

.field-label {
    font-size: 11px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 12px 0 5px 0;
    letter-spacing: 0.1em;
}
.field-data {
    font-weight: 700;
    color: var(--theme-contrast);
    margin-bottom: 8px;
}
select.field-data {
    color: white;
    background-color: var(--theme-contrast);
}
select:disabled {
    background-color: gray;
}

/* This has to go here rather than in the question component because it
   applies only to some injected HTML, so angular thinks it isn't used */
.evidence h4 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0px;
}
.evidence-text h4 {
    margin-top: 15px;
    font-size: 15px;
}
form {
    font-size: 14px;
}
form select {
    color: var(--theme-text);
    background-color: white;
    padding: 5px;
    width: auto;
    max-width: 400px;
    border-radius: 5px;
    min-width: 160px;
}
